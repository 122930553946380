export class BaseModel {
    public uid: string;
}
export class products {
    uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    name: string;
    description: string;
    image: string;
    file_path: string;
    stock: string;
    price: number;
    last_price: number;
    show_last_price: boolean;
    is_visible: boolean;
    category_uid: string;
    locations_uid: string;
}
export class users {
    email: string;
    displayName: string;
    photoURL: string;
    emailVerified: boolean;
    uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
}
export class orders_status {
    uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    name: string;
    is_delivery_status: boolean;
}
export class orders {
    uid: string;
    user_uid: string;
    extra_status: string = '';
    orders_status_uid: string;
    payment_gateway_uid: string;
    customer_payment_uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    status_uid: string;
    is_delivery: boolean = false;
    date_cooked: Date;
    date_delivery_start: Date;
    date_delivery_end: Date;
    lat_delivery_start: string;
    lon_delivery_start: string;
    lat_delivery_end: string;
    lon_delivery_end: string;
    deparment: string;
    municipality: string;
    direction: string;
    zone: string;
    branch: string;
    place_delivery_user: string;
    place_delivery_name: string;
    who_receives: string;
    is_card: boolean;
    cash_quantity: number;
    change_quantity: number;
    phone: string;
    amount: number;
    discount: number;
    discount_percent: number;
    items: object[];
    is_yoken: boolean = false;
}
export class orders_items {
    uid: string;
    order_uid: string;
    product_uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    price: number;
    quantity: number;
    subtotal: number;
}
export class payment_gateways {
    uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    name: string;
    key_test: string;
    key_live: string;
    url_test: string;
    url_live: string;
    is_card: boolean;
}
export class customers_gateways {
    uid: string;
    user_uid: string;
    customer_test_id: string;
    customer_live_id: string;
    payment_gateway_uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    card_name: string;
    card_brand: string;
    card_month: string;
    card_year: string;
    card_last4: string;
    card_country: string;
}
export class customers_payments {
    uid: string;
    user_uid: string;
    payment_gateway_uid: string;
    pay_id: string;
    amount: string;
    is_card: boolean;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
}
export class locations {
    uid: string;
    location_category_uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    name: string;
    is_visible: boolean;
}
export class locations_category {
    uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    name: string;
}
export class Category {
    public uid: string = '';
    public created_at: Date = new Date();
    public updated_at: any = null;
    public deleted_at: any = null;
    public description: string = '';
    public file_path: string = '';
    public image: string = '';
    public banner: string = '';
    public is_visible: boolean = false;
    public is_active: boolean = true;
    public commerce_uid: string = '';
    public name: string = '';
    public extra_options: Array<{}> = [];
    public position: number = 0;
    public uid_category_yelou: string = '';

    constructor() { }
}

export class Product {
    public uid: string = '';
    public created_at: Date = new Date();
    public updated_at: any = null;
    public deleted_at: any = null;
    public description: string = '';
    public uid_product_yelou: string = '';
    public show_description: boolean = false;
    public file_path: string = '';
    public image: string = '';
    public is_visible: boolean = false;
    public is_active: boolean = true;
    public label: string = '';
    public last_price: number = 0;
    public locations_uid: string = '';
    public name: string = '';
    public price: number = 0;
    public position: number = 0;
    public membership_price: number = 0;
    public initial_price: number = 0;
    public show_last_price: boolean = false;
    public stock: number = 0;
    public product_emmi: any = {};
    public show_stock: boolean = true;
    public product_code: string = '';
    public show_product_code: boolean = false;
    public category_uid: string = '';
    public commerce_uid: string = '';
    public extra_options: Array<{}> = [];
    public ingredients: Array<{}> = [];
    public is_yoken: boolean = false;
    public yoken_price: number = 0;
}
export class Order {
    public uid: string = '';
    public counter: string = '';
    public user_uid: string = '';
    public orders_status_uid: string = '';
    public status_user: string = '';
    public payment_gateway_uid: string = '';
    public customer_payment_uid: string = '';
    public preparation_time: string = '';
    public created_at: Date = new Date();
    public order_preparation_date: Date = new Date();
    public is_credits: boolean = true;
    public credits_result: any = 0;
    public updated_at: any = null;
    public deleted_at: any = null;
    public status_uid: string = '';
    public is_delivery: boolean = true;
    public is_tasting: boolean = false;
    public is_expired_product: boolean = false;
    public is_yoken: boolean = false;
    
    public is_partnership: boolean = false;
    public code_used: string = '';

    public date_cooked: any = null;
    public date_delivery_start: any = null;
    public date_delivery_end: any = null;
    public created_at_formatted: string = '';
    public lat_delivery_start: any = '';
    public location: string = '';
    public lon_delivery_start: any = '';
    public lat_delivery_end: any = '';
    public lng_delivery_end: any = '';
    public deparment: string = '';
    public municipality: string = '';
    public direction: string = '';
    public zone: string = '';
    public sucursal: string = '';
    public sucursal_uid: string = '';
    public commerce_name: string = '';
    public commerce_image: string = '';
    public commerce_uid: string = '';
    public place_delivery_user: string = '';
    public place_delivery_name: string = '';
    public promo_code: string = '';
    public promo_type: string = '';
    public promo_discount: number = 0;
    public promo_data: any = {};
    public is_promotion:boolean = false;//promotion code option
    public who_receives: string = '';
    public is_card: boolean = false;
    public is_n1co_card: boolean = false;
    public is_bitcoin: boolean = false;
    public cash_quantity: number = 0;
    public change_quantity: number = 0;
    public phone: string = '';
    public amount: number = 0;
    public discount: number = 0;
    public discount_percent: number = 0;
    public items: any = [];
    public track_positions: Array<object> = [];
    public subtotalForYelou: number = 0;
    public subtotalForRide: number = 0;
    public total: number = 0;
    public branch: string = '';
    public yet_name: string = '';
    public yet_phone: string = '';
    public yet_image: string = '';
    public yet_uid: string = '';
    public yet_lat: string = '';
    public yet_lng: string = '';
    public is_notified_commerce: boolean = false;
    public is_notified_client: boolean = false;
    public is_notified_yet: boolean = false;
    public manager_uid: string = '';
    public rate_service: string = '';
    public rate_service_comment: string = '';
    public badgeColor: string = '';
    public note: string = '';
    public cancel_reason: string = ''; 
    public dedication: string = '';
    public extra_status: string = '';
    public is_order_schedule: boolean = false;
    public order_schedule: Date;
    public order_schedule_format: string = "";
    public rememberSchedule: boolean = false;
    public rememberNumber:string = "";
    public isGift:boolean = false;
    public is_credits_wallet: boolean = true;
    public commerce_direction: string = '';
    public is_member:boolean = false;//bitcoin option
    public total_yelou_go_discount: any = 0;
    public credits_wallet_result: any = 0;
    public pay_bitcoin_yet:boolean = false;// yet will generate the qr code
    public btc_checkout_id:string = '';//BTC payment checkout id
    public need_invoice: boolean = false;
    public need_ticket: boolean = false;
    public stripe_transaction: any = {};
    public totalInCents: number = 0;
    public totalYoken?: number = 0;
    public amountYoken?: number = 0;
}
export class Commerce {
    public uid: string = '';
    public name: string = '';
    public is_enabled: boolean = false;
    public is_visible: boolean = true;
    public is_available: boolean = false;
    public close_automatically: boolean = false;
    public isYellowGoAffiliate: boolean = false;
    public new_commerce: boolean = false;
    public full_direction: string = '';
    public zone: string = '';
    public latitude: string = '';
    public longitude: string = '';
    public file_path: string = '';
    public image: string = '';
    public register_number: string = '';
    public nit: string = '';
    public membership: string = '';
    public method_payment: string = '';
    public tel: string = '';
    public cel: string = '';
    public category_commerce: string = 'Conveniencias';
    public manager_user_uid: string = '';
    public managers: Array<{ user_uid: string }> = [];
    public created_at: Date = new Date();
    public schedule = {};
    public lat:Number = 0;
    public lng:Number = 0;
}
export class AffiliatedVerification {
    public uid: string = '';
    public empresa_tipo_de_persona: string = '';
    public empresa_razon_social: string = '';
    public empresa_nombre_comercial: string = '';
    public empresa_direccion_oficina_central: string = '';
    public empresa_municipio: string = '';
    public empresa_departamento: string = '';
    public empresa_telefono: string = '';
    public empresa_registro_unico_contribuyente: string = '';
    public empresa_nit: string = '';
    public empresa_giro_actividad_economica: string = '';
    public empresa_pagina_web: string = '';
    public empresa_correo: string = '';
    public empresa_cantidad_sucursales: string = '';

    public representante_legal_tipo: string = '';
    public representante_legal_nombre: string = '';
    public representante_nacimiento: string = '';
    public representante_sexo: string = '';
    public representante_dui: string = '';
    public representante_nit: string = '';
    public representante_movil: string = '';
    public representante_fijo: string = '';
    public representante_correo: string = '';
    public representante_forma_notificar: string = '';

    public persona_natural_nombre: string = '';
    public persona_natural_nacimiento: string = '';
    public persona_natural_sexo: string = '';
    public persona_natural_dui: string = '';
    public persona_natural_nit: string = '';
    public persona_natural_movil: string = '';
    public persona_natural_fijo: string = '';
    public persona_natural_correo: string = '';
    public persona_natural_forma_notificar: string = '';

    public category_commerce: string = '';
    public negocio_tiempo_funcionando: string = '';
    public negocio_firmado: boolean = false;
    public negocio_sellado: boolean = false;
    public updated_at: Date = new Date();
    public user_uid: string = '';
    public file_path: string = '';
    public image: string = '';

}

export class AffiliatedVerificationJuridica {
    public uid: string = '';
    public empresa_tipo_de_persona: string = '';
    public empresa_razon_social: string = '';
    public empresa_nombre_comercial: string = '';
    public empresa_direccion_oficina_central: string = '';
    public empresa_municipio: string = '';
    public empresa_departamento: string = '';
    public empresa_telefono: string = '';
    public empresa_registro_unico_contribuyente: string = '';
    public empresa_nit: string = '';
    public empresa_giro_actividad_economica: string = '';
    public empresa_pagina_web: string = '';
    public empresa_correo: string = '';
    public empresa_fecha_constitucion: string = '';
    public empresa_cantidad_sucursales: string = '';

    public representante_legal_tipo: string = '';
    public representante_legal_nombre: string = '';
    public representante_nacimiento: string = '';
    public representante_sexo: string = '';
    public representante_dui: string = '';
    public representante_nit: string = '';
    public representante_movil: string = '';
    public representante_fijo: string = '';
    public representante_forma_notificar: string = '';

    public category_commerce: string = '';
    public negocio_tiempo_funcionando: string = '';
    public negocio_firmado: boolean = false;
    public negocio_sellado: boolean = false;
    public updated_at: Date = new Date();
    public user_uid: string = '';
    public file_path: string = '';
    public image: string = '';

    public empresa_banco_cuenta_bancaria: string = '';
    public empresa_banco_nombre: string = '';
    public empresa_banco_tipo_de_cuenta: string = '';
    public empresa_contacto_nombre: string = '';
    public empresa_contacto_email: string = '';
    public empresa_contacto_telefono: string = '';
}
export class AffiliatedVerificationNatural {
    
    public uid: string = '';
    public empresa_tipo_de_persona: string = '';
    public empresa_nombre_comercial: string = '';
    public empresa_direccion_oficina_central: string = '';
    public empresa_municipio: string = '';
    public empresa_departamento: string = '';
    public empresa_telefono: string = '';
    public empresa_registro_unico_contribuyente: string = '';
    public empresa_nit: string = '';
    public empresa_giro_actividad_economica: string = '';
    public empresa_pagina_web: string = '';
    public empresa_correo: string = '';
    public empresa_fecha_constitucion: string = '';
    public empresa_cantidad_sucursales: string = '';

    public persona_natural_nombre: string = '';
    public persona_natural_nacimiento: string = '';
    public persona_natural_sexo: string = '';
    public persona_natural_dui: string = '';
    public persona_natural_nit: string = '';
    public persona_natural_movil: string = '';
    public persona_natural_fijo: string = '';
    public persona_natural_correo: string = '';
    public persona_natural_forma_notificar: string = '';

    public category_commerce: string = '';
    public negocio_tiempo_funcionando: string = '';
    public negocio_firmado: boolean = false;
    public negocio_sellado: boolean = false;
    public updated_at: Date = new Date();
    public user_uid: string = '';
    public file_path: string = '';
    public image: string = '';

    public empresa_banco_cuenta_bancaria: string = '';
    public empresa_banco_nombre: string = '';
    public empresa_banco_tipo_de_cuenta: string = '';
    public empresa_contacto_nombre: string = '';
    public empresa_contacto_email: string = '';
    public empresa_contacto_telefono: string = '';
}

import * as firebase from 'firebase/app';

export class CommerceLocation {
    public uid: string = '';
    public commerce_uid: string = '';
    public commerce_image: string = '';
    public manager_user_uid: string = '';
    public is_visible: boolean = true;
    public is_enabled: boolean = true;
    public full_direction: string = '';
    public name: string = '';
    public time: Number = new Date().getTime();
    public timestamp: Date = new Date();
    public category_commerce: string = 'Conveniencia';
    public coordinates: firebase.firestore.GeoPoint = new firebase.firestore.GeoPoint(0, 0);
    // public coordinates: GeoPoint = new GeoPoint(0, 0);
}
