import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventsService } from '../events/events.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import Swal from 'sweetalert2';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  audio: any;
  audio_2: any;
  intervalSound: NodeJS.Timeout;
  token;

  constructor(
    private firestore: FirestoreService,
    private http: HttpClient,
    public events: EventsService,
    private afm: AngularFireMessaging,
    private functions: AngularFireFunctions,
  ) {
    this.events.subscribe('alert:sound', (data) => {
      // console.log(data)
      if (data['sound']) { 
        this.initSound();
        Swal.fire({
          title: 'Nueva Orden',
          toast: true, 
          position: 'bottom-end',
          background: '#FFF1BF', 
          showConfirmButton: false, 
          timer: 6e3, 
          timerProgressBar: true, 
          icon: 'info'
        }); 
      }
      else { 
        this.stopSound(); 
      }
    });
    this.events.subscribe('alert:sound_2', (data) => {
      // console.log(data)
      if (data['sound']) { 
        this.initSound2();
        Swal.fire({
          title: 'Nueva Petición de Retiro de Efectivo',
          toast: true, 
          position: 'bottom-end',
          background: '#FFF1BF', 
          showConfirmButton: false, 
          timer: 6e3, 
          timerProgressBar: true, 
          icon: 'info'
        }); 
      }
      else { 
        this.stopSound2(); 
      }
    });
    if (this.audio) { this.audio = null; }
    this.audio = new Audio();
    this.audio.src = './../../assets/audio/hangouts_message.ogg';
    this.audio.load();

    if (this.audio_2) { this.audio_2 = null; }
    this.audio_2 = new Audio();
    this.audio_2.src = './../../assets/audio/notificacion_iphone.mp3';
    this.audio_2.load();
  }

  public init(uid): void {
    if (!!this.token == false) {
      // this.afm.requestToken.subscribe(
      //   (token) => {
      //     this.token = token;
      //     // console.log('notification: init(): requestToken: ' + this.token);
      //     this.firestore.setPushToken(uid, token);
      //     this.afm.messages.subscribe((data: any) => {
      //       // console.log(data);
      //       // console.log('notification: init(): message: ' + JSON.stringify(data));
      //       if (data.wasTapped) {
      //         //this.toast.showToast(data.title + ' ' + data.body);
      //         Swal.fire({
      //           title: data.title + ' ' + data.body,
      //           toast: true, position: 'top-end', 
      //           showConfirmButton: false, 
      //           timer: 6e3, 
      //           timerProgressBar: true, 
      //           icon: 'success'
      //         });
      //       } else {
      //         // this.toast.showToast(data.title + ' ' + data.body);
      //         Swal.fire({
      //           title: data.title + ' ' + data.body,
      //           toast: true, position: 'top-end', 
      //           showConfirmButton: false, 
      //           timer: 6e3, 
      //           timerProgressBar: true, 
      //           icon: 'success'
      //         });
      //       }
      //     }), ((e) => {
      //       console.log(e);
      //     });;
      //   },
      //   (error) => { console.error(error); }
      // );
    }
  }

  notify(nameFunction, paramsObject) {
    if (nameFunction != '' && paramsObject != null) {
      const callable = this.functions.httpsCallable(nameFunction);
      const obs = callable(paramsObject);
    }
  }


  public getPushTokenFromUsr(uid) {
    return new Promise((resolve, reject) => {
      this.firestore.getOne('users', uid).subscribe((data) => {
        // console.log(data);
        resolve(data);
      }), (function (error) {
        console.log(error);
        reject(false);
      });
    });
  }

  // Send a push notification given the pushToken, and the message.
  // Change the title, body, and data according to your requirements.
  public sendPush(token: string, title: string, body: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const postParams = {
        notification: {
          title: title,
          body: body,
          badge: 1,
          sound: 'default',
          click_action: 'FCM_PLUGIN_ACTIVITY',
          icon: 'ic_launcher'
        },
        data: {
          title: title,
          body: body,
          forceStart: 1,
        },
        to: token,
        priority: 'high',
        restricted_package_name: '',
        "apns": {
          "headers": {
            "apns-priority": "5"
          }
        },
      };

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'key=' + environment.gcmKey
      });

      const options = { headers: headers };

      this.http.post('https://fcm.googleapis.com/fcm/send', postParams, options).subscribe(response => {
        resolve(response);
      }, err => {
        reject(err);
      });
    });
  }

  initSound() {
    let this_ = this;
    this.audio.play();
    this.audio.loop = true;

    clearInterval(this.intervalSound);
    this.intervalSound = setInterval(function () {
      //console.log('event!!!')
      this_.events.publish('alert:changeColor', { change: true });
    }, 1000);
  }

  stopSound() {
    if (this.audio) {
      this.audio.pause();
      //this.audio = null;
    }
    clearInterval(this.intervalSound);
  }

  async initSound2() {
    this.audio_2.play();
    this.audio_2.loop = true;

    await setTimeout(() => {
      // console.log('event!!!')
      this.stopSound2();
    }, 2500);
  }

  stopSound2() {
    if (this.audio_2) {
      this.audio_2.pause();
      //this.audio = null;
    }
  }

  requestPermission(): Observable<NotificationPermission> {
    return new Observable<NotificationPermission>(observer => {
      if (!('Notification' in window)) {
        // console.log('entro 1');
        observer.next('default');
        observer.complete();
      } else if (Notification.permission !== 'default') {
        // console.log('entro 2');
        observer.next(Notification.permission);
        observer.complete();
      } else {
        // console.log('entro 3');
        Notification.requestPermission().then(permission => {
          // console.log('entro 4');
          observer.next(permission);
          observer.complete();
        });
      }
      // console.log('entro 5');
    });
  }

  async sendPOST_AppNotifications(messages){
    console.log("http: ", messages);
    var raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // return this.http.post('https://us-central1-itwc-9657a.cloudfunctions.net/invoices-v1', raw, {headers}).subscribe(data => {
    return this.http.post('https://us-central1-yelou-uat-v2023.cloudfunctions.net/invoice', raw, {headers}).subscribe(data => {
      console.log(data);
     }, error => {
      console.log(error);
    });
  }
}
