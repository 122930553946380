import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { EventsService } from 'src/app/shared/service/events/events.service';
import { FirestoreService } from 'src/app/shared/service/firestore/firestore.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;

  returnUrl: any;
  sub1: Subscription;
  email = '';
  password: any = '';
  user = new User();
  data: any = {};
  background: any = { 'background': 'url(/assets/images/jewellery/pro/12.jpg) no-repeat center center/ cover' };
  background_image: any = { 'background': 'url(/assets/images/jewellery/pro/12.jpg) no-repeat 0 0 / cover' };
  type_input: string = 'password';
  icon: string = 'eye';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private event: EventsService,
    private auth: AuthService,
    private firestore: FirestoreService,
  ) {
    this.createLoginForm();
    this.createRegisterForm();
    this.onLogout();

    if (localStorage.getItem('background') && localStorage.getItem('background_image')) {
      var objeto1 = localStorage.getItem('background');
      var objeto2 = localStorage.getItem('background_image');

      this.background = objeto1.hasOwnProperty('background') ? JSON.parse(localStorage.getItem('background')) : { 'background': 'url(/assets/images/jewellery/pro/12.jpg) no-repeat center center/ cover' };
      this.background_image = objeto2.hasOwnProperty('background') ? JSON.parse(localStorage.getItem('background_image')) : { 'background': 'url(/assets/images/jewellery/pro/12.jpg) no-repeat 0 0 / cover' };
    } else {
      this.background = { 'background': 'url(/assets/images/jewellery/pro/12.jpg) no-repeat center center/ cover' };
      this.background_image = { 'background': 'url(/assets/images/jewellery/pro/12.jpg) no-repeat 0 0 / cover' };
    }

    this.firestore.getOne('settings', 'admin').subscribe(res => {
      // console.log("settings: ",res);
      // console.log("background: ",this.background);
      if (res) {
        this.data = res;
        this.background = { 'background': 'url(' + this.data.background_image_login_sm + ') no-repeat center center/ cover' }
        this.background_image = { 'background': 'url(' + this.data.background_image_login + ') no-repeat 0 0 / cover' }
        localStorage.setItem('background', JSON.stringify(this.background));
        localStorage.setItem('background_image', JSON.stringify(this.background_image));
      }
    })
  }

  owlcarousel = [
    {
      title: "Yelou Afiliados",
      desc: "Bienvenido Accede a tu cuenta.",
    }
  ]
  owlcarouselOptions = {
    loop: false,
    items: 1,
    dots: false
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: [''],
      password: [''],
    })
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: [''],
      password: [''],
      confirmPassword: [''],
    })
  }


  ngOnInit() {
  }

  onSubmit() {

  }

  onLoggedin(e: Event) {
    // e.preventDefault();
    // localStorage.setItem('isLoggedin', 'true');
    // if (localStorage.getItem('isLoggedin')) {
    //   this.router.navigate([this.returnUrl]);
    // }

    Swal.fire({
      icon: 'info',
      title: 'Espera por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      timer: 6e3
    })

    this.auth.loginWithEmail(this.email, this.password).then(res => {
      // console.log("loginWithEmail: ",res);
      if (res.user.emailVerified) {
        // console.log("Email verified");
        this.auth.getUser2().then(async userLocal => {
          console.log("getUser2", userLocal);
          if (userLocal.is_admin) {
            if (res.user.emailVerified || userLocal.emailVerified) {
              this.user = userLocal;
              localStorage.setItem('isLoggedin', 'true');
              // localStorage.setItem('session_last_email', this.user.email);
              // localStorage.setItem('session_last_password', this.password)
              this.event.publish('session-start', true);
              localStorage.setItem('session_type', 'correo');

              if (userLocal.is_enabled === true) {
                // console.log("getUser2: ",userLocal)
                // localStorage.setItem('data_session',JSON.stringify({}));
                Swal.close();
                Swal.fire(
                  'Bienvenido!',
                  'Bienvenido de vuelta!',
                  'success'
                )
                this.router.navigate(['/dashboard/default']);
              } else {
                Swal.close();
                Swal.fire(
                  'COMPLETA EL FORMULARIO DE REGISTRO DE COMERCIO AFILIADO',
                  'Bienvenido!',
                  'success'
                )
                this.router.navigate(['/verification']);
              }
            } else {
              Swal.close();
              Swal.fire(
                'No puedes ingresar por el momento',
                "Verifica tu correo o intentalo mas tarde",
                'warning'
              )
            }
          } else {
            Swal.close();
            Swal.fire(
              'Warning',
              "No puedes ingresar!",
              'warning'
            )
          }

        }).catch((e) => {
          Swal.close();
          Swal.fire(
            'Error',
            e,
            'error'
          )
        });
      } else {
        Swal.close();
        Swal.fire(
          'Warning',
          "Confirme su dirección de correo electrónico",
          'warning'
        )
        // this.presentAlert("Error","Please confirm your email");
      }
    }).catch(e => {
      console.log("loginWithEmail: ", e.message);
      Swal.close();
      Swal.fire(
        'Error',
        "Verifica tus credenciales!",
        'error'
      )
    });
  }

  ShowPassword() {
    // console.log("password");
    if (this.type_input === "password") {
      this.type_input = "text";
      this.icon = "eye-off";
    } else {
      this.type_input = "password";
      this.icon = "eye";
    }
  }

  async askResetPass() {
    await Swal.fire({
      title: "Ingresa tu correo!",
      icon: 'info',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Continuar',
      denyButtonText: `Cancelar`,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log("result: ", result);
      if (result.isConfirmed) {
        if (result.value !== '') {
          Swal.fire({
            icon: 'info',
            title: 'Espera por favor...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            timerProgressBar: true,
            timer: 6e3
          });
          this.auth.resetPassword(result.value).then(r => {
            Swal.close();
            Swal.fire(
              'Revisa tu correo',
              'Hemos enviado un link de cambio de contraseña',
              'success'
            )
          }).catch(err => {
            console.log(err)
            Swal.close();
            Swal.fire(
              'Error',
              err.message,
              'error'
            )
          });
        } else {
          Swal.close();
          Swal.fire(
            'Completa el campo',
            '',
            'warning'
          )
        }
      }
    })
  }

  /**
  * Logout
  */
  onLogout() {
    this.auth.logout().then((e) => {
      // console.log(e);
      // console.log('SignOut()');
    }).catch((e) => { console.log(e); });
  }

}
